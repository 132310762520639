import {
    Box,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
} from "@mui/material";
import useHasSomePermission from "@repo/shared/hooks/useHasSomePermission.ts";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export const drawerWidth = 240;

export type NavigationItem = {
    label: string;
    icon: ReactNode;
    href: string;
    permissions: string[];
};

type DrawerContentProps = {
    navigationItems: NavigationItem[];
    onClose: () => void;
};

const DrawerContent = ({ navigationItems, onClose }: DrawerContentProps): ReactNode => {
    const hasSomePermission = useHasSomePermission();
    const navigate = useNavigate();

    return (
        <>
            <Toolbar />
            <Divider />
            <List>
                {navigationItems.map(
                    (item) =>
                        hasSomePermission(item.permissions) && (
                            <ListItem
                                key={item.href}
                                disablePadding
                                onClick={() => {
                                    navigate(item.href);
                                    onClose();
                                }}
                            >
                                <ListItemButton>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.label} />
                                </ListItemButton>
                            </ListItem>
                        ),
                )}
            </List>
        </>
    );
};

type DrawerContainerProps = {
    navigationItems: NavigationItem[];
    mobileOpen: boolean;
    onDrawerClose: () => void;
    onDrawerTransitionEnd: () => void;
};

const DrawerContainer = ({
    navigationItems,
    mobileOpen,
    onDrawerClose,
    onDrawerTransitionEnd,
}: DrawerContainerProps): ReactNode => {
    const drawerContent = (
        <DrawerContent navigationItems={navigationItems} onClose={onDrawerClose} />
    );

    return (
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={onDrawerTransitionEnd}
                onClose={onDrawerClose}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
            >
                {drawerContent}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", md: "block" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
                open
            >
                {drawerContent}
            </Drawer>
        </Box>
    );
};

export default DrawerContainer;
