import { apiUrl } from "@repo/shared/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createNullableResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const identityAttributesSchema = z.object({
    nickname: z.string(),
    emailAddress: z.string(),
    picture: z
        .object({
            id: z.string(),
            small: z.string(),
            normal: z.string(),
            original: z.string(),
        })
        .nullable(),
});

const sessionSelector = createDataSelector(
    createNullableResourceSelector({
        type: "session",
        relationships: {
            identity: {
                relationshipType: "one",
                include: {
                    type: "identity",
                    attributesSchema: identityAttributesSchema,
                },
            },
            permissions: {
                relationshipType: "many",
                resourceType: "permission",
            },
        },
    }),
);

export type Session = NonNullable<ReturnType<typeof sessionSelector>>;
export type Identity = Session["identity"];

export const useCurrentSessionQuery = (): UseQueryResult<Session | null> => {
    return useQuery({
        queryKey: ["sessions", "current"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/identity/sessions/current"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        staleTime: 60 * 15 * 1000,
        select: sessionSelector,
    });
};
