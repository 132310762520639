import type { ReactNode } from "react";
import { type FunctionComponent, type LazyExoticComponent, Suspense } from "react";

type Props = {
    App: LazyExoticComponent<FunctionComponent>;
};

const AppPage = ({ App }: Props): ReactNode => {
    return (
        <Suspense>
            <App />
        </Suspense>
    );
};

export default AppPage;
