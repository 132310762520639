import BusinessIcon from "@mui/icons-material/Business.js";
import EuroIcon from "@mui/icons-material/Euro";
import FingerprintIcon from "@mui/icons-material/Fingerprint.js";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import type { ReactNode } from "react";

export type App = {
    label: string;
    icon: ReactNode;
    href: string;
    permissions: string[];
};

export const apps: App[] = [
    {
        label: "Identity",
        icon: <FingerprintIcon />,
        href: "/identity",
        permissions: [
            "identity:identities-full-access",
            "identity:identities-read-only",
            "identity:groups-full-access",
            "identity:groups-read-only",
            "identity:roles-full-access",
            "identity:roles-read-only",
            "identity:api-keys-full-access",
        ],
    },
    {
        label: "HR",
        icon: <BusinessIcon />,
        href: "/hr",
        permissions: ["hr:full-access"],
    },
    {
        label: "Logistics",
        icon: <LocalShippingIcon />,
        href: "/logistics",
        permissions: ["logistics:full-access", "logistics:manage"],
    },
    {
        label: "Accounting",
        icon: <EuroIcon />,
        href: "/accounting",
        permissions: [
            "accounting:full-access",
            "accounting:request-budget",
            "accounting:request-refunds",
        ],
    },
    {
        label: "Registration",
        icon: <HowToRegIcon />,
        href: "/registration",
        permissions: [
            "registration:manage-registrations",
            "registration:manage-lottery",
            "registration:statistics-internal",
        ],
    },
];
