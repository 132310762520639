import AppsIcon from "@mui/icons-material/Apps";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Box,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Popover,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { apps } from "@repo/shared/apps.tsx";
import DrawerContainer, {
    drawerWidth,
    type NavigationItem,
} from "@repo/shared/components/Layout/DrawerContainer.tsx";
import { useSession } from "@repo/shared/components/SessionProvider/index.ts";
import UserAvatar from "@repo/shared/components/UserAvatar/index.ts";
import useHasSomePermission from "@repo/shared/hooks/useHasSomePermission.ts";
import { bindMenu, bindPopover, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import AppButton from "./AppButton.tsx";

type Props = {
    title: string;
    navigationItems?: NavigationItem[];
};

const Layout = ({ title, navigationItems }: Props): ReactNode => {
    const appPopupState = usePopupState({ variant: "popover", popupId: "app-popup" });
    const userMenuState = usePopupState({ variant: "popover", popupId: "user-menu" });
    const session = useSession();
    const hasSomePermission = useHasSomePermission();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const displayedApps = apps.filter((app) => hasSomePermission(app.permissions));

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar
                position="fixed"
                sx={
                    navigationItems
                        ? {
                              width: { md: `calc(100% - ${drawerWidth}px)` },
                              ml: { md: `${drawerWidth}px` },
                          }
                        : undefined
                }
            >
                <Toolbar>
                    {navigationItems && (
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>

                    {displayedApps.length > 0 && (
                        <>
                            <IconButton color="inherit" {...bindTrigger(appPopupState)}>
                                <AppsIcon />
                            </IconButton>
                            <Popover
                                {...bindPopover(appPopupState)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                slotProps={{
                                    paper: {
                                        sx: {
                                            p: 2,
                                            width: { xs: 300, sm: 400 },
                                        },
                                    },
                                }}
                            >
                                <Grid container spacing={2}>
                                    {displayedApps.map((app) => (
                                        <Grid item xs={6} sm={4} key={app.href}>
                                            <AppButton {...app} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Popover>
                        </>
                    )}

                    <IconButton edge="end" {...bindTrigger(userMenuState)}>
                        <UserAvatar identity={session.identity} />
                    </IconButton>

                    <Menu {...bindMenu(userMenuState)}>
                        <Box
                            sx={{
                                width: 390,
                                p: 2,
                            }}
                        >
                            <Stack direction="row" spacing={2} alignItems="center">
                                <UserAvatar identity={session.identity} size={64} />
                                <div>
                                    <Typography>{session.identity.nickname}</Typography>
                                    <Typography variant="body2">
                                        {session.identity.emailAddress}
                                    </Typography>
                                </div>
                            </Stack>
                        </Box>

                        <Divider sx={{ mb: 1 }} />

                        <MenuItem
                            onClick={() => {
                                window.location.href = new URL(
                                    "/settings",
                                    import.meta.env.VITE_APP_IDENTITY_URL,
                                ).toString();
                            }}
                        >
                            <ListItemIcon>
                                <ManageAccountsIcon />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                window.location.href = new URL(
                                    "/logout",
                                    import.meta.env.VITE_APP_IDENTITY_URL,
                                ).toString();
                            }}
                        >
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            {navigationItems && (
                <DrawerContainer
                    navigationItems={navigationItems}
                    mobileOpen={mobileOpen}
                    onDrawerClose={handleDrawerClose}
                    onDrawerTransitionEnd={handleDrawerTransitionEnd}
                />
            )}
            <Box
                component="main"
                sx={
                    navigationItems
                        ? {
                              flexGrow: 1,
                              p: 3,
                              width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` },
                          }
                        : { p: 3, width: "100%" }
                }
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
};

export default Layout;
