import { Box, CircularProgress } from "@mui/material";
import { type Session, useCurrentSessionQuery } from "@repo/shared/queries/session.ts";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect } from "react";

const SessionContext = createContext<Session | null>(null);

type Props = {
    identityUiBaseUrl: string;
    children: ReactNode;
};

const SessionProvider = ({ identityUiBaseUrl, children }: Props): ReactNode => {
    const sessionQuery = useCurrentSessionQuery();

    useEffect(() => {
        if (sessionQuery.data === null) {
            const loginUrl = new URL("/login", identityUiBaseUrl);
            loginUrl.searchParams.set("returnTo", window.location.href);
            window.location.href = loginUrl.toString();
        }
    }, [sessionQuery.data, identityUiBaseUrl]);

    if (!sessionQuery.data) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return <SessionContext.Provider value={sessionQuery.data}>{children}</SessionContext.Provider>;
};

export const useSession = (): Session => {
    const context = useContext(SessionContext);

    if (!context) {
        throw new Error("Context used outside provider");
    }

    return context;
};

export default SessionProvider;
