import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
    Alert,
    Container,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import { apps } from "@repo/shared/apps.tsx";
import useHasSomePermission from "@repo/shared/hooks/useHasSomePermission.ts";
import type { ReactNode } from "react";
import { useNavigate } from "react-router";

const HomePage = (): ReactNode => {
    const hasSomePermission = useHasSomePermission();
    const displayedApps = apps.filter((app) => hasSomePermission(app.permissions));
    const navigate = useNavigate();

    if (displayedApps.length === 0) {
        return <Alert severity="error">You do not have access to any management apps.</Alert>;
    }

    return (
        <Container>
            <Typography sx={{ mb: 2 }}>
                Select an app below or through the menu in the corner.
            </Typography>

            <Paper>
                <List>
                    {displayedApps.map((app) => (
                        <ListItem key={app.href} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    navigate(app.href);
                                }}
                            >
                                <ListItemIcon>{app.icon}</ListItemIcon>
                                <ListItemText>{app.label}</ListItemText>
                                <KeyboardArrowRightIcon />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Container>
    );
};

export default HomePage;
