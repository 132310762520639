import { useSession } from "@repo/shared/components/SessionProvider/index.ts";

const useHasSomePermission = () => {
    const session = useSession();

    return (permissions: string[]) => {
        return (
            permissions.length === 0 ||
            permissions.some((permission) =>
                session.permissions.some((current) => current.id === permission),
            )
        );
    };
};

export default useHasSomePermission;
