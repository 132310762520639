import { Button, styled } from "@mui/material";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    icon: ReactNode;
    label: string;
    href: string;
};

const StyledButton = styled(Button)({
    width: "100%",
    textTransform: "none",
    flexDirection: "column",
    color: "white",
});

const AppButton = ({ icon, label, href }: Props): ReactNode => {
    const navigate = useNavigate();

    return (
        <StyledButton onClick={() => navigate(href)}>
            {icon}
            {label}
        </StyledButton>
    );
};

export default AppButton;
